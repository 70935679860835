import React from "react";

import { Section, Container } from "@components/global";

import FaqItem from "@common/FaqItem";
import ExternalLink from "@common/ExternalLink";

const FAQS = [
  {
    title: "Address",
    content: () => (
      <>
        <div>
          <p>221, Deerwood Chase </p>
          <p>Nirvana Country, Sector 50</p>
          <p>Gurgaon, Haryana, 122018</p>
        </div>
      </>
    ),
  },
  {
    title: "Phone/Fax",
    content: () => (
      <>
        {" "}
        <p> Primary Mobile: +91-9958077550</p>
        <p> Secondary Mobile: +91-9811434038</p>
      </>
    ),
  },
  {
    title: "E-Mail",
    content: () => (
      <>
        <p>madhupant@hotmail.com</p>
      </>
    ),
  },
];

const Faq = () => (
  <Section id="contact">
    <Container>
      <h1 style={{ marginBottom: 40 }}>Contact information: </h1>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

export default Faq;
