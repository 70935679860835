import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { Section, Container } from "@components/global";

const About2 = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(sourceInstanceName: { eq: "art" }, name: { eq: "sp1" }) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        art_1: file(sourceInstanceName: { eq: "art" }, name: { eq: "sp2" }) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => (
      <Section id="sparshmani" accent>
        <Container>
          <Grid>
            <Art>
              <Img fluid={data.art_fast.childImageSharp.fluid} />
            </Art>
            <Art>
              <Img fluid={data.art_1.childImageSharp.fluid} />
            </Art>
            <h2>About Sparsh Mani</h2> <h2>स्पर्शमणि के बारे में</h2>{" "}
          </Grid>
          <Grid>
            <div>
              {" "}
              <p>
                <b>Sparsh Mani</b> Sparsh Mani believes in awakening latent
                talent among children and youth by conducting creative
                workshops, developing inspiring audio and video books and
                publication of innovative literary material. Sparsh Mani engages
                highly reputed, experienced and creative Resource Persons for
                the production of its material and organising its Programmes and
                activities.
              </p>
            </div>
            <div>
              <p>
                स्पर्शमणि पारस पत्थर का ही पर्यायवाची है | जिस प्रकार पारस के
                सम्पर्क में आकर लोहा भी सोना बन जाता है, उसी प्रकार यह अपेक्षित
                है कि स्पर्शमणि द्वारा आयोजित कार्यो, कार्यशालाओं, प्रकाशनों एवं
                अन्य अभिनव सामग्रियों से सम्पर्क में आने वाले सभी सहभागी
                प्रभावित होंगे, उनका व्यक्तित्व विकसित होगा और उनमें निहित
                प्रतिभा का स्वतः ही प्रस्फुटन होगा |
              </p>{" "}
            </div>
          </Grid>
          <Grid>
            <h3>Objectives</h3> <h3>उद्देश्य</h3>
          </Grid>
          <Grid>
            <div>
              {" "}
              <p>
                <ul>
                  <li>
                    To provide opportunities for self-learning in innovative
                    ways.
                  </li>
                  <li>
                    To enhance and sustain creativity by enabling adoption of
                    unconventional and less traveled paths and building
                    resilience.
                  </li>
                  <li>
                    To transform the reluctant and passive learner to an
                    enthausiastic and active learner.
                  </li>
                  <li>
                    To identify means for self reliance, thereby enhancing
                    self-esteem.
                  </li>
                  <li>
                    To develop the overall personality by instilling the right
                    kind of values and attitudes.
                  </li>
                  <li>
                    To enable the ability to effectively face real life
                    challenges.
                  </li>
                </ul>
              </p>
            </div>
            <div>
              {" "}
              <p>
                <ul>
                  <li>
                    प्रतिभागियों की प्रसुप्त प्रतिभा को उद्भासित कर, उन्हें अपनी
                    योग्यता का अधिकतम उपयोग करने के अवसरों की प्राप्ति |
                  </li>
                  <li>
                    हिन्दी भाषा के सुगमता से समझने की सुविधा प्रदान कर तथा
                    अंग्रेजी भाषा में सम्प्रेषण करने की योग्यता के अवसर दे कर,
                    सीखने के द्विआयामी अवसरों की प्राप्ति |
                  </li>
                  <li>
                    आत्म-सम्मान और आत्म-विश्वास विकसित कर, आत्म गौरव की वृद्धि |{" "}
                  </li>
                  <li>
                    सर्वांगीं विकास एवं संतुलित व्यक्तित्व के निर्माण के अवसर
                    उपलब्ध |
                  </li>
                  <li>
                    सीखने-सिखाने की प्रक्रिया में बाधक अवरोधों को समाप्त करने के
                    अवसर उपलब्ध |
                  </li>
                  <li>
                    सीखने के औपचारिक एवं अनौपचारिक तरीकों का अनोखा ताल मेल |
                  </li>
                </ul>
              </p>
            </div>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  text-align: left;

  justify-items: center;
  margin: 24px 0;

  ${(props) =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${(props) =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 40%;
`;

export default About2;
