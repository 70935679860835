import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { Container } from "@components/global";
import ExternalLink from "@common/ExternalLink";

import YouTube from "@static/icons/Youtube.svg";
import AmazonIcon from "@static/icons/amazon.svg";

const SOCIAL = [
  {
    icon: YouTube,
    link: "https://www.youtube.com/channel/UCvfpc6VoJAyJ3pJspUiAKog",
  },
  {
    icon: AmazonIcon,
    link:
      "https://www.amazon.in/s?i=stripbooks&rh=p_27%3ADr.+MADHU+PANT&ref=dp_byline_sr_book_1s",
  },
];

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        art_pot: file(
          sourceInstanceName: { eq: "team" }
          name: { eq: "satrangi" }
        ) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => (
      <React.Fragment>
        {/* <Art>
          <Img
            fluid={data.art_pot.childImageSharp.fluid}
            style={{ width: 580, maxWidth: "100%", marginBottom: -16 }}
          />
        </Art> */}
        <FooterWrapper>
          <StyledContainer>
            <Copyright>
              <h2>Dr. Madhu Pant</h2>
              <span>
                © 2020
                {` `}
                <ExternalLink href="">Dr. Madhu Pant</ExternalLink>
              </span>
            </Copyright>
            <SocialIcons>
              {SOCIAL.map(({ icon, link }) => (
                <ExternalLink key={link} href={link}>
                  <img src={icon} alt="link" />
                </ExternalLink>
              ))}
            </SocialIcons>
          </StyledContainer>
        </FooterWrapper>
      </React.Fragment>
    )}
  />
);

const SocialIcons = styled.div`
  display: flex;

  img {
    margin: 0 8px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-top: 40px;
  }
`;

const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.color.primary};
  padding: 32px 0;
`;

const Copyright = styled.div`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.black.regular};

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const Art = styled.figure`
  display: flex;
  justify-content: center;
  margin: 0;
  margin-top: 48px;
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;

export default Footer;
