import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import Photo from "@static/icons/photo.svg";

import { Section, Container } from "@components/global";
import { Carousel } from "react-responsive-carousel";

import SingleBook from "@common/SingleBook";
import ExternalLink from "@common/ExternalLink";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Faq({ data }) {
  const pics = {
    allFlickrPhoto: {
      edges: [
        {
          node: {
            id: "bb7ee64a-5ea7-57ba-b506-6f2aba0f56f8",
            title: "Dr. Madhu Pant",
            description:
              "Dr. Madhu Pant with Prime Minister Mr. P.V. Narsimha Rao and Mr. Arjun Singh",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5141/5549777372_eaa8d79a6c_c.jpg",
            width_c: 784,
            height_c: 544,
          },
        },
        {
          node: {
            id: "2405fa1c-2ace-5941-8b68-3f64b2c6bf37",
            title: "Dr. Madhu Pant",
            description: "Dr. Madhu Pant with Ms. Sonia Gandhi",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5055/5549777320_2b683e4694_c.jpg",
            width_c: 775,
            height_c: 540,
          },
        },
        {
          node: {
            id: "e3c0ac30-11f7-5896-a1fb-eaef5baf2f58",
            title: "Dr. Madhu Pant",
            description: "Dr. Madhu Pant with Ms. Sonia Gandhi",
            tags: "dr pant madhu",
            url_c: "/gallery/rg2.png",
            width_c: null,
            height_c: null,
          },
        },
        {
          node: {
            id: "f80e0e8e-40c0-514c-9acf-205045dce687",
            title: "Dr. Madhu Pant",
            description: "",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5070/5549777184_2f836e3d5d_c.jpg",
            width_c: 800,
            height_c: 533,
          },
        },
        {
          node: {
            id: "681a1cb8-dd44-50a3-9990-2f27fa353e44",
            title: "Dr. Madhu Pant",
            description: "",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5068/5549775602_e4cfe17b88_c.jpg",
            width_c: 800,
            height_c: 533,
          },
        },
        {
          node: {
            id: "0b3addac-c427-5cd6-bcbf-048fd882eaca",
            title: "pic-014",
            description: "",
            tags: "",
            url_c:
              "https://live.staticflickr.com/5051/5549775300_9f90a1fd92_c.jpg",
            width_c: 800,
            height_c: 533,
          },
        },
        {
          node: {
            id: "73023387-ab13-5ee3-a45a-c736c3cabe31",
            title: "pic-015",
            description: "",
            tags: "",
            url_c:
              "https://live.staticflickr.com/5227/5549773892_a92e158b8f_c.jpg",
            width_c: 800,
            height_c: 533,
          },
        },
        {
          node: {
            id: "39ceea8c-c6a6-544f-b8ec-00f71d985ed5",
            title: "Dr. Madhu Pant",
            description: "",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5269/5549772478_db74686dd4_c.jpg",
            width_c: 800,
            height_c: 514,
          },
        },
        {
          node: {
            id: "378bba40-b0c8-5c89-9483-aa10390c4990",
            title: "Dr. Madhu Pant",
            description: "",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5061/5549188771_c775e165c7_c.jpg",
            width_c: 789,
            height_c: 540,
          },
        },
        {
          node: {
            id: "7bf0add9-731b-504d-be71-6d98cb148975",
            title: "Dr. Madhu Pant",
            description: "",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5189/5549188713_98a2680d51_c.jpg",
            width_c: 800,
            height_c: 533,
          },
        },
        {
          node: {
            id: "3f4588b2-041f-5f23-b7df-7bcd950f938e",
            title: "Dr. Madhu Pant",
            description: "Dr. Madhu Pant with Ms. Sonia Gandhi",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5134/5549186891_d0a78c19c7_c.jpg",
            width_c: 800,
            height_c: 533,
          },
        },
        {
          node: {
            id: "029eee84-0d38-51d7-a56e-334450c98f28",
            title: "Dr. Madhu Pant",
            description: "",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5070/5549768226_5b0dbb6cb6_c.jpg",
            width_c: 800,
            height_c: 533,
          },
        },
        {
          node: {
            id: "d040cb5c-a4c8-5baa-9833-c06aeff6ed54",
            title: "Dr. Madhu Pant",
            description:
              "Dr. Madhu Pant  with President Dr. Shankar Dayal Sharma",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5270/5549183449_747ed62921_c.jpg",
            width_c: 800,
            height_c: 519,
          },
        },
        {
          node: {
            id: "6583a31a-a5bd-53df-becf-4d2a083b5cf1",
            title: "Dr. Madhu Pant",
            description:
              "Dr. Madhu Pant  with President Dr. Shankar Dayal Sharma",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5294/5549766296_c755b37c60_c.jpg",
            width_c: 800,
            height_c: 524,
          },
        },
        {
          node: {
            id: "f1e7db24-911d-5d6a-a2b8-eb7a9d17761a",
            title: "Dr. Madhu Pant",
            description: "",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5149/5549766184_4882029856_c.jpg",
            width_c: 800,
            height_c: 521,
          },
        },
        {
          node: {
            id: "5bb19c65-e50e-5264-8b2e-d5c2368dbce8",
            title: "Dr. Madhu Pant",
            description: "",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5224/5549182347_a5f641449e_c.jpg",
            width_c: 800,
            height_c: 521,
          },
        },
        {
          node: {
            id: "813c3ce8-4b72-5f03-b604-b64e33fe7c02",
            title: "Dr. Madhu Pant",
            description: "",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5227/5549181883_0ca0c30077_c.jpg",
            width_c: 800,
            height_c: 533,
          },
        },
        {
          node: {
            id: "365c2d5c-9bec-5d7e-80b8-bf1bc9b55a79",
            title: "Dr. Madhu Pant",
            description: "",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5224/5549762714_c5918dcc4c_c.jpg",
            width_c: 800,
            height_c: 533,
          },
        },
        {
          node: {
            id: "91402827-8771-5ffb-bab3-60b1b55df923",
            title: "Dr. Madhu Pant",
            description: "Dr. Madhu Pant  with Prime Minister Mr. Rajiv Gandhi",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5268/5549177433_3a87cabb26_c.jpg",
            width_c: 800,
            height_c: 533,
          },
        },
        {
          node: {
            id: "4f4cd406-7ca8-5aa9-90f5-1b67a31bc52e",
            title: "Dr. Madhu Pant",
            description:
              "Dr. Madhu Pant  with Prime Minister Mr. Chandra Sekhar",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5176/5549758324_8ab3ecac19_c.jpg",
            width_c: 800,
            height_c: 533,
          },
        },
        {
          node: {
            id: "73160b7f-84ae-5c02-9cdb-f70fc8393026",
            title: "Dr. Madhu Pant",
            description: "Dr. Madhu Pant  with Prime Minister Mr. Rajiv Gandhi",
            tags: "dr pant madhu",
            url_c: "/gallery/rg22.png",
            width_c: 533,
            height_c: 800,
          },
        },
        {
          node: {
            id: "80a6672b-f86a-5b25-bde8-2b719775452e",
            title: "Dr. Madhu Pant",
            description:
              "Dr. Madhu Pant with Prime Minister Mr. Chandra Sekhar",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5186/5549754614_7c3ed8dea3_c.jpg",
            width_c: 800,
            height_c: 533,
          },
        },
        {
          node: {
            id: "f207ffbf-4e3d-56d2-a99f-738b38eef075",
            title: "Dr. Madhu Pant",
            description: "",
            tags: "dr pant madhu",
            url_c:
              "https://live.staticflickr.com/5292/5549169037_7b6baae9ed_c.jpg",
            width_c: 800,
            height_c: 533,
          },
        },
      ],
    },
  };
  return (
    <Section id="photo gallery" accent>
      <Container>
        <h1 style={{ marginBottom: 40 }}>
          {" "}
          <img src={Photo} alt="link" />
          Photo Gallery
        </h1>
        <Carousel autoPlay>
          {pics.allFlickrPhoto.edges.map((item) => {
            return (
              <div key={item.node.id}>
                <img alt="" src={item.node.url_c} />
                {item.node.description == "" ? (
                  <></>
                ) : (
                  <p className="legend">{item.node.description}</p>
                )}
              </div>
            );
          })}
        </Carousel>
      </Container>
    </Section>
  );
}
export default Faq;
