import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql, Link, navigate } from "gatsby";
import Img from "gatsby-image";
import Youtube from "@static/icons/Youtube.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Section, Container } from "@components/global";

function Team({ data }) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const center = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  };

  const videos = data.allYoutubeVideo.edges;

  const other = videos.slice(0, videos.length - 5);
  const ActionButton = styled.button`
    font-weight: 400;
    margin-right: 5px;
    margin-left: 0 !important;
    background: #c00 !important;
    border-radius: 4px;
    padding: 16px 24px 17px;
    border: none;
    outline: 0;
    color: #fff !important;
    font-size: 16px;
    vertical-align: top;
    letter-spacing: normal !important;
    position: relative;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    cursor: pointer;
    :hover {
      box-shadow: 0 6px 7px 0 rgba(0, 0, 0, 0.15), 0 0 5px 0 rgba(0, 0, 0, 0.1);
      opacity: 1;
      transform: translateY(-2px) !important;
      transition: 0.35s !important;
    }
  `;

  return (
    <Section id="youtube" accent="secondary">
      <Container style={{ position: "relative" }}>
        <div style={{ display: "flex" }}>
          <div>
          <img src={Youtube} alt="link" />
          </div>
          <div style={{ marginTop: 'auto', marginBottom: "auto" }}>
            <h1>YouTube Bal Bhavan Jaipur{" "}
        </h1>
          </div>
        </div>
        <Carousel
          swipeable={true}
          draggable={false}
          renderButtonGroupOutside={true}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          renderDotsOutside
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          deviceType={"desktop"}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {other.map(({ node }) => {
            return (
              <Link
                to={`https://www.youtube.com/watch?v=${node.videoId}`}
                style={{ textDecoration: "none" }}
              >
                <div key={node.id}>
                  <img
                    style={center}
                    src={node.thumbnail.url}
                    alt={node.title}
                    height={150}
                    width={200}
                  />{" "}
                  <Subtitle style={center}>{node.title}</Subtitle>
                </div>
              </Link>
            );
          })}
        </Carousel>
      </Container>
    </Section>
  );
}

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-template-rows: min-content;
  grid-gap: 10px;
  justify-content: space-between;
  width: 100%;
  margin-top: 72px;

  @media (max-width: ${(props) => props.theme.screen.lg}) {
    justify-content: start;
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    grid-gap: 24px;
  }
`;

const Art = styled.figure`
  width: 800px;
  margin: -80px 0;
  position: absolute;
  top: 0;
  left: 70%;

  @media (max-width: ${(props) => props.theme.screen.lg}) {
    top: 20%;
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    display: none;
  }
`;

const ArtMobile = styled.figure`
  width: 100%;
  margin: 0;
  display: none;
  margin-top: 64px;
  margin-bottom: -60%;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    display: block;
  }
`;

const Title = styled.p`
  margin-top: 16px;
  color: ${(props) => props.theme.color.black.regular};
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: #564f62;
  line-height: 22px;
  max-width: 200px;
  text-align: center;
  color: ${(props) => props.theme.color.black.light};
`;

export default Team;
