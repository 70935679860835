import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { Section, Container } from "@components/global";

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "team" }
          name: { eq: "About_Oval" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => (
      <Section id="about">
        <Container>
          <Grid inverse>
            <Art>
              <Img fluid={data.art_fast.childImageSharp.fluid} />
            </Art>
            <div>
              <h2>About Dr. Pant</h2>{" "}
              <p>
                Dr. Madhu Pant began her professional journey as a writer and is
                best described as an innovative educator committed to developing
                creativity among children. Her writings, publications and non
                formal teaching methods target enhancement of children’s innate
                imagination and self exploration through logical, often
                scientific concepts. She is an engaging storyteller whose
                writings introduce and propagate cultural values, environmental
                consciousness and civic responsibilities in children. Her unique
                style of writing effectively encourages children to learn by
                drawing their own conclusions through observation and logical
                inference. Dr. Pant, as an environmentalist, has conducted
                workshops with children to create awareness on several issues
                ranging from water preservation to environmental cleanliness.
                Her many achievements centered around child development include
                initiation of a Bal Sree scheme to promote innovative
                expressions, development and distribution of books and CDs
                focused on creating environmental consciousness to 5000 schools
                all over India and translation of her book series ‘ The Clean
                Forest’ in 15 Indian regional languages by the Information and
                Broadcasting Ministry to promote the significance of a Clean
                India. She is a recipient of the prestigious N.H.K. Japan Award
                as writer for the educational film “What is Science?”, National
                Award for popularising Science and Technology amongst children,
                Hindi Academy Award for her book “Khelo to Jane”, National Video
                Film Award for her film on idioms and phrases, and National
                Award of Best Audio Production for the programme “A peep into
                the forest”.
              </p>
            </div>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${(props) =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${(props) =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
