import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";

import { Section, Container } from "@components/global";

import SingleBook from "@common/SingleBook";
import ExternalLink from "@common/ExternalLink";

const FAQS = [
  {
    title: "The Rainbow Umbrella",
    image: "satrangi.jpg",
    url: "/books/satrangi-chhatri.pdf",
    content: () => <>Children's folk tale from India</>,
  },
  {
    title: "Cat with a Noisy Collar",
    image: "billi.jpg",
    url: "/books/billi.pdf",
    content: () => (
      <>
        Two brave brothers, one human and one part ogre, set out to free the
        world of the horrible monsters, the Khokoshes.
      </>
    ),
  },
  {
    title: "The Shadow",
    image: "parchhai.jpg",
    url: "/books/parchhai.pdf",
    content: () => <>An epic adventure story for children</>,
  },
  {
    title: "Billi's Tour of Dilli",
    image: "ghumi-dilli.jpg",
    url: "/books/Biili-ne-ghumi-dilli.pdf",
    content: () => <>Forthcoming...</>,
  },
  {
    title: "The Chatty Kitchen",
    image: "rasoi.jpg",
    url: "/books/rasoi-ghar-ki-khirki.pdf",
    content: () => <>Forthcoming...</>,
  },
  {
    title: "The Cocky Murga",
    image: "akru-murga.jpg",
    url: "/books/akru-murga.pdf",
    content: () => <>Forthcoming...</>,
  },
  {
    title: "The Intelligent Donkey!",
    image: "gadha2.png",
    url: "/books/gadha-jo-akalmand.pdf",
    content: () => <>Forthcoming...</>,
  },
  {
    title: "The Forgetful Fly",
    image: "bee.png",
    url: "/books/bee-on-high.pdf",
    content: () => <>Forthcoming...</>,
  },
  {
    title: "Owl Needs Glasses",
    image: "owl_story.png",
    url: "/books/owl_story.pdf",
    content: () => <>Forthcoming...</>,
  },
  {
    title: "Shoes for the Snake",
    image: "snake.png",
    url: "/books/snake.pdf",
    content: () => <>Forthcoming...</>,
  },
];

const Art = styled.figure`
  width: 100%;
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      width: 100%;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  align-items: top;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(min-content, max-content);

  grid-gap: 64px;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;
  }
`;

const Faq = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "team" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Section id="books">
        <Container>
          <h1 style={{ marginBottom: 40 }}>Stories for Children</h1>
          <div>
            <Grid>
              {FAQS.map(({ title, image, url, content }) => {
                const img = data.allFile.edges.find(
                  ({ node }) => node.relativePath === image
                ).node;
                return (
                  <SingleBook
                    title={title}
                    url={url}
                    image={img.childImageSharp.fluid}
                    key={title}
                  >
                    {content()}
                  </SingleBook>
                );
              })}
            </Grid>
          </div>
        </Container>
      </Section>
    )}
  />
);

export default Faq;
