import React from "react";
import { graphql } from "gatsby";
import Layout from "@common/Layout";
import Navbar from "@common/Navbar";

import Header from "@sections/Header";
import About from "@sections/About";
import About2 from "@sections/About2";

import Brands from "@sections/Brands";
import Team from "@sections/Team";
import Channel2 from "@sections/Channel2";

import Faq from "@sections/Faq";
import Footer from "@sections/Footer";
import Books from "@sections/Books";
import "./../styles/style.css";

const IndexPage = ({ data }) => (
  <Layout>
    <Navbar />
    <Header yt={data} />
    <Team data={data} />
    <Channel2 data={data} />
    <Books />
    <Brands data={data} />

    <About />
    <About2 />
    <Faq />
    <Footer />
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query MyQuery {
    allYoutubeVideo(sort: { fields: publishedAt, order: DESC }, filter: {channelId: {eq: "UCvfpc6VoJAyJ3pJspUiAKog"}}) {
      edges {
        node {
          id
          publishedAt
          videoId
          title
          description
          thumbnail {
            url
            width
            height
          }
        }
      }
    }
    channel2:  allYoutubeVideo(sort: { fields: publishedAt, order: DESC }, filter: {channelId: {eq: "UCNgBtvYq25Hb13y4TybAJiA"}}) {
      edges {
        node {
          id
          publishedAt
          videoId
          title
          description
          thumbnail {
            url
            width
            height
          }
        }
      }
    }
    allFlickrPhoto {
      edges {
        node {
          id
          title
          description
          tags
          url_c
          width_c
          height_c
        }
      }
    }
  }
`;
